/* src/components/Footer.css */
.footer {
    padding: 1rem;
    text-align: center;
    background-color: #f8f9fa;
  }
  
  .footer a {
    margin: 0 0.5rem;
    text-decoration: none;
    color: #333;
  }
  