/* src/pages/HomePage.css */
.section {
    padding: 2rem;
    text-align: center;
  }
  
  .orange-section {
    background-color: orange;
    display: flex;
    align-items: center;
  }
  
  .orange-section img {
    max-width: 50%;
    height: auto;
  }
  
  .text-content {
    max-width: 50%;
    padding: 1rem;
  }
  
  .grid-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  .grid-item {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 5px;
  }
  
  .download-section {
    background-color: #f8f9fa;
    padding: 2rem;
  }
  
  .download-buttons img {
    width: 150px;
    margin: 1rem;
  }
  