/* src/components/Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f8f9fa;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    height: 40px;
    margin-right: 0.5rem;
  }
  
  nav a {
    margin: 0 0.5rem;
    text-decoration: none;
    color: #333;
  }
  